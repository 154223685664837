// import Dexie from "dexie";
import * as localforage from "localforage";

const createInstance = (dbName, tableName) => {
  const db = localforage.createInstance({
    driver: localforage.INDEXEDDB, // LocalForage を使用する
    name: dbName,
    storeName: tableName,
  });

  return db;
};

const IndexedDBHelpers = {
  createTable: async ({ dbName, tableName, key, values }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const myLF = createInstance(dbName, tableName);

        await myLF.setItem(key, values);
        return resolve();
      } catch (e) {
        return reject(e);
      }
    });
  },

  getTable: async ({ dbName, tableName, key }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const myLF = createInstance(dbName, tableName);

        return resolve(await myLF.getItem(key));
      } catch (e) {
        return reject(e);
      }
    });
  },

  deleteDB: async ({ dbName, tableName }) => {
    // return await Dexie.delete(dbName);
    return new Promise(async (resole, reject) => {
      try {
        const myLF = createInstance(dbName, tableName);

        await myLF.clear();
        return resole();
      } catch (e) {
        return reject(e);
      }
    });
  },
};

export default IndexedDBHelpers;
