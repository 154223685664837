import React from "react";
import { Initial, LoginForm, MainLayout } from "./containers/index";
// eslint-enable no-unused-vars
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/app.css";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Initial} />
          <Route exact path="/login" component={LoginForm} />
          <Route exact path="/main" component={MainLayout} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
