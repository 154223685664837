import React from "react";
import { SaveOutlined, WarningOutlined } from "@ant-design/icons";
import { Spin, Button, Card, Result, Tree, message } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingOperations from "reducks/settings/operations";
import { getCommonSelector, getCorporationIdSelector } from "reducks/settings/selectors";

import Global from "../models/Global";
import { DepartmentsHelpers } from "helpers";
import "../assets/common.css";

const localize = Global.localize;

class Departments extends React.Component {
  state = {
    loading: false,
    syncing: false,
    success: false,
    warning: false,
    updating: false,
    commonInfo: [],
    departments: [],
    treeData: [],
    checkedKeys: [],
    expandedKeys: [],
    latestSyncDate: localize.DepartmentTitleLatestUpdate,
  };

  reloadData(data) {
    var self = this;
    this.setState({ loading: true });

    this.props.actions.fetchSettings().then(() => {
      const { corporationId, common } = this.props;
      const collaborationService = common.collaborationService;
      DepartmentsHelpers.loadDepartmentData(data, corporationId, collaborationService)
        .then(async (resData) => {
          self.setState({ departments: resData.syncedData });
          self.setState({
            latestSyncDate: localize.DepartmentTitleLatestUpdate.replace("{latestSyncDate}", resData.latestSyncDate),
          });
          if (resData.commonInfo !== undefined) {
            self.setState({ commonInfo: resData.commonInfo });
          }

          var treeData = DepartmentsHelpers.getTreeData(this.state.departments);
          var treeFormatData = DepartmentsHelpers.getTreeFormat(treeData);
          var expandedKeys = DepartmentsHelpers.getExpandedKeys(treeFormatData);
          var checkedKeys = DepartmentsHelpers.getCheckedKeys(treeFormatData);

          self.setState({
            treeData: treeFormatData,
            expandedKeys: expandedKeys,
            checkedKeys: checkedKeys,
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  componentDidMount() {
    this.reloadData({});
  }

  onCheck = (checkedKeys, e) => {
    var setCheckedKeys = DepartmentsHelpers.setCheckedKeys(this.state.departments, checkedKeys, e.node.props.eventKey);
    this.setState({ checkedKeys: setCheckedKeys });
  };

  onSyncDepartments = (e) => {
    var self = this;
    self.setState({
      syncing: true,
      updating: true,
      success: false,
      warning: false,
    });

    const { corporationId, common } = this.props;
    const { collaborationService } = common;

    var object = Object.assign(this.state.commonInfo, { corporationId: corporationId, collaborationService: collaborationService });
    DepartmentsHelpers.loadSyncedDepartments(object).then((data) => {

      if (data.syncedData && data.latestSyncDate) {
        self.setState({
          syncing: false,
          updating: false,
          success: true,
          warning: false,
        });
        this.reloadData(data);
      } else {
        self.setState({
          syncing: false,
          updating: false,
          success: false,
          warning: true,
        });
      }
    });
  };

  onSave = () => {
    var self = this;
    const { checkedKeys, departments } = this.state;
    const { corporationId } = this.props;
    self.setState({ updating: true });

    var updateData = departments.map(function (item) {
      if (checkedKeys.checked.indexOf(item.group_id) >= 0 || checkedKeys.halfChecked.indexOf(item.group_id) >= 0) {
        item.visible = true;
      } else {
        item.visible = false;
      }
      return item;
    });

    message.destroy();
    DepartmentsHelpers.updateDepartments(updateData, corporationId)
      .then((result) => {
        self.setState({ updating: false });
        message.success(localize.DepartmentUpdateSuccess);
      })
      .catch((error) => {
        self.setState({ updating: false });
        message.error(localize.DepartmentUpdateError);
        console.error(error);
      });
  };

  renderLoading() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Spin size="large" />
      </div>
    );
  }

  render() {
    return (
      <div>
        <Card
          title={
            this.state.loading ? null : this.props.common.collaborationService === "wowtalk" ? (
              <div>{localize.DepartmentWowtalkTitle}</div>
            ) : (
              <div>{localize.DepartmentTeamsTitle}</div>
            )
          }
          style={{ margin: "16px" }}
        >
          <div className="department-note">
            <div className="icon">
              <WarningOutlined />
            </div>
            <div className="note-content">
              {this.state.loading ? null : this.props.common.collaborationService === "wowtalk" ? (
                <div>
                  <div>{localize.DepartmentNote1}</div>
                  <div>{localize.DepartmentNote2}</div>
                </div>
              ) : (
                <div>
                  <div>{localize.DepartmentNoteTeamsSync}</div>
                  <div>{localize.DepartmentNote2}</div>
                </div>
              )}
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "24px" }}>
            <Button type="primary" onClick={this.onSyncDepartments} loading={this.state.syncing} disabled={this.state.syncing}>
              {localize.DepartmentSync}
            </Button>
          </div>
          <Card bordered={false}>
            {this.state.syncing ? <Result icon={<Spin size="large" />} status="info" title={localize.DepartmentStsSyncingTitle} /> : null}
            {this.state.success ? <Result status="info" title={localize.DepartmentStsInfoTitle} /> : null}
            {this.state.warning ? (
              <Result
                status="warning"
                title={localize.DepartmentStsWarningTitle1}
                subTitle={localize.DepartmentStsWarningTitle2.replace("{collaborationService}", this.props.common.collaborationService)}
              />
            ) : null}
            {this.state.loading ? (
              this.renderLoading()
            ) : (
              <Card size="small" title={localize.DepartmentTitleCurrentInfo}>
                <Card size="small" title={this.state.latestSyncDate} className="mb-8">
                  <div className="mb-8">
                    <div>{localize.DepartmentNote3}</div>
                    <div>{localize.DepartmentNote4}</div>
                  </div>
                  <Card size="small">
                    <Tree
                      checkable={true}
                      checkStrictly={true}
                      autoExpandParent={true}
                      defaultExpandAll={true}
                      onCheck={this.onCheck}
                      disabled={this.state.updating}
                      checkedKeys={this.state.checkedKeys}
                      expandedKeys={this.state.expandedKeys}
                      treeData={this.state.treeData}
                    />
                  </Card>
                </Card>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    icon={<SaveOutlined />}
                    type="danger"
                    ghost
                    style={{ width: "50%" }}
                    disabled={this.state.updating}
                    loading={this.state.updating}
                    onClick={this.onSave}
                  >
                    {localize.Save}
                  </Button>
                </div>
              </Card>
            )}
          </Card>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corporationId: getCorporationIdSelector(state),
    common: getCommonSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Departments);
