import React from "react";
import { CloudSyncOutlined, SearchOutlined, StopOutlined, UserAddOutlined, SafetyOutlined } from "@ant-design/icons";
import { Table, Spin, Button, Card, Modal, Typography, Input, message } from "antd";
import { blue } from "@ant-design/colors";
import Global from "../models/Global";
import { UserHelpers } from "helpers";
import UserForm from "./UserForm";
import "../assets/common.css";
import config from "../models/config";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingOperations from "reducks/settings/operations";
import * as loadingOperations from "reducks/loading/operations";
import { getCommonSelector, getCorporationIdSelector, getAdvanceSelector } from "reducks/settings/selectors";
import { getLoadingState } from "reducks/loading/selectors";
import DepartmentsHelpers from "../helpers/DepartmentsHelpers";

const localize = Global.localize;

const { Text } = Typography;

class UserList extends React.Component {
  state = {
    editMode: false,
    userFormVisible: false,
    showSyncResults: false,
    syncResults: {},
    dataSource: [],
    originalDataSource: [],
    listDepartments: [],
    useTeams: false,
  };

  constructor(props) {
    super(props);
    this.onUserCancel = this.onUserCancel.bind(this);
    this.onUserConfirm = this.onUserConfirm.bind(this);
    this.onUserDelete = this.onUserDelete.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.renderWowtalkSyncDialog = this.renderWowtalkSyncDialog.bind(this);
    this.renderTeamsSyncDialog = this.renderTeamsSyncDialog.bind(this);
    this.onTableChange = this.onTableChange.bind(this);
    this.departmentReload = this.departmentReload.bind(this);
    this.renderUserForm = this.renderUserForm.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.loading.state !== nextProps.loading.state) {
      return true;
    } else if (this.state.userFormVisible !== nextState.userFormVisible) {
      return true;
    } else if (this.state.showSyncResults !== nextState.showSyncResults) {
      return true;
    }
    return false;
  }

  onSyncWowtalkUser() {
    this.renderWowtalkSyncDialog();
  }

  onUserConfirm = (data) => {
    let { dataSource } = this.state;

    let dataSourceItem = dataSource.find((item) => item.uid === data.uid);
    let updateIndex = dataSource.findIndex((item) => item.uid === data.uid);

    dataSource[updateIndex] = { ...dataSourceItem, ...data };

    this.setState({
      userFormVisible: false,
      editData: null,
      dataSource: dataSource.slice(),
    });
  };

  onAddUser = () => {
    this.setState({
      editMode: false,
      userFormVisible: true,
      editData: null,
    });
  };

  onUserCancel = () => {
    this.setState({ userFormVisible: false, editData: null });
  };

  onUserDelete = (uid) => {
    let { dataSource } = this.state;
    const dataSourceFilter = dataSource.filter((item) => item.uid !== uid);

    this.setState({
      userFormVisible: false,
      editData: null,
      dataSource: dataSourceFilter,
    });
    this.originalDataSource = dataSourceFilter;
  };

  onClick(record) {
    // 編集時にはWowtalkもTeamsもグループはgroupで統一する
    this.setState({
      editMode: true,
      userFormVisible: true,
      editData: record,
    });
  }

  departmentReload = async () => {
    const { corporationId } = this.props;
    return DepartmentsHelpers.loadData(corporationId)
      .then((data) => {
        var treeData = DepartmentsHelpers.getTreeData(data);
        var treeSelectData = DepartmentsHelpers.getTreeSelectFormat(treeData);

        this.setState({ listDepartments: treeSelectData });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  reloadData = () => {
    const self = this;

    this.props.actions.showLoading().then(async () => {
      // console.log(`before fetchSettings`, this.props.location.state)
      await this.props.actions.fetchSettings(this.props.location.state);

      const { corporationId, collaborationService, tenantId, location } = this.props;

      UserHelpers.loadUserList(corporationId, collaborationService, tenantId)
        .then(async (resData) => {
          self.originalDataSource = resData;
          // var ret = await isSyncByTeams();

          // if (ret) {
          self.setState({ dataSource: resData });
          await this.departmentReload();
          this.props.actions.hideLoading();
          // }
        })
        .catch((e) => {
          console.error(e);
          this.props.actions.hideLoading();
        });
    });
  };

  renderButtons = () => (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <Button loading={this.props.loading.state} icon={<UserAddOutlined />} type="primary" onClick={this.onAddUser}>
        {localize.AddMember}
      </Button>
      <div style={{ width: "20px" }}></div>
      {this.props.common.collaborationService === "teams" && (
        <Button loading={this.props.loading.state} icon={<CloudSyncOutlined />} type="dashed" onClick={this.renderTeamsSyncDialog}>
          {localize.SyncTeamsMember}
        </Button>
      )}
      {this.props.common.collaborationService === "wowtalk" && (
        <Button loading={this.props.loading.state} icon={<CloudSyncOutlined />} type="dashed" onClick={this.renderWowtalkSyncDialog}>
          {localize.SyncMember}
        </Button>
      )}
    </div>
  );

  renderUserForm() {
    return (
      <UserForm
        // ref={(el) => (this.editor = el)}
        onConfirm={this.onUserConfirm}
        onCancel={this.onUserCancel}
        onDelete={this.onUserDelete}
        editMode={this.state.editMode} // true: 更新, false: 新規作成
        editData={this.state.editData}
        listUsers={this.state.dataSource}
        listDepartments={this.state.listDepartments}
        visible={this.state.userFormVisible}
        title={this.state.editMode ? localize.EditUser : localize.NewUser}
        onReloadData={this.reloadData}
      />
    );
  }

  renderSyncResults() {
    return (
      <Modal
        title="同期完了"
        maskClosable={false}
        transitionName="am-slide-down"
        visible={this.state.showSyncResults}
        closable={false}
        onOk={() => this.setState({ showSyncResults: false })}
        cancelButtonProps={{ style: { display: "none" } }}
        cancelText={localize.Cancel}
        okText={localize.Confrim}
      >
        <p>
          <Text>新規作成: {this.state.syncResults.created} 名</Text>
        </p>
        <p>
          <Text>更新: {this.state.syncResults.updated} 名</Text>
        </p>
        <p>
          <Text>無効化: {this.state.syncResults.disabled} 名</Text>
        </p>
      </Modal>
    );
  }

  async syncWowtalkUser() {
    const func = Global.func;
    this.props.actions.showLoading();
    const { corporationId } = this.props;
    this.setState({ syncResults: {} });
    const syncWowTalkUsers = func.httpsCallable("user-syncWowTalkUsers", { timeout: 500000 });
    const total = await syncWowTalkUsers({ corporationId: corporationId, mode: config.mode });

    const { code, result } = total.data;
    if (code === 200) {
      this.props.actions.hideLoading();
      this.setState({ syncResults: result, showSyncResults: true }, () => {
        this.reloadData();
      });
    } else {
      message.warning(localize.SyncMemberErrorParameterConfirmInput);
      this.props.actions.hideLoading();
    }

    // if (code === 400) {
    //   message.warning(localize.SyncMemberErrorParameterNotEnough);

    //   // this.setState({ loading: false }, () => this.reloadData());
    // } else if (code === 500) {
    //   message.warning(localize.SyncMemberErrorParameterConfirmInput);
    //   this.props.actions.hideLoading();
    //   this.reloadData();
    //   // this.setState({ loading: false }, () => this.reloadData());
    // } else {
    //   const ret = total.data;

    //   this.props.actions.hideLoading();
    //   this.setState({ syncResults: ret, showSyncResults: true }, () => {
    //     this.reloadData();
    //   });
    // }
  }

  async syncTeamsUser() {
    const func = Global.func;
    this.props.actions.showLoading();

    this.setState({ syncResults: {} });
    const syncMicrosoftUsers = func.httpsCallable("user-syncMicrosoftUsers", { timeout: 500000 });
    const total = await syncMicrosoftUsers({ corporationId: this.props.corporationId });

    const { code, result } = total.data;
    if (code === 200) {
      this.props.actions.hideLoading();
      this.setState({ syncResults: result, showSyncResults: true }, () => {
        this.reloadData();
      });
    } else {
      message.warning(localize.SyncMemberErrorParameterNotEnough);
      this.props.actions.hideLoading();
      this.reloadData();
    }
  }

  renderTeamsSyncDialog() {
    const modal = Modal.confirm();

    modal.update({
      title: "Teams ユーザーを同期する",
      content: "OKを押すと、Microsoft365アカウントでWowDeskへログインできるメンバーの新規作成と更新をします。よろしいですか？",
      transitionName: "am-slide-down",
      cancelText: localize.Cancel,
      onOk: (e) => {
        this.syncTeamsUser();
        modal.destroy();
      },
      onCancel: (e) => {
        modal.destroy();
      },
    });
  }

  renderWowtalkSyncDialog() {
    const modal = Modal.confirm();

    modal.update({
      title: "WowTalkユーザーを同期する",
      content:
        "OKを押すと、WowTalk IDでWowDeskへログインできるメンバーの新規作成と更新をします。登録済みのWowTalk IDが一致しないメンバーは無効化されます。よろしいですか？",
      transitionName: "am-slide-down",
      cancelText: localize.Cancel,
      onOk: (e) => {
        this.syncWowtalkUser();
        modal.destroy();
      },
      onCancel: (e) => {
        modal.destroy();
      },
    });
  }

  getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`${localize.Search} ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          style={{ width: 90, marginRight: 8 }}
        >
          {localize.Search}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} style={{ width: 90 }}>
          {localize.Reset}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => {
      if (!text) text = "";
      return this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    },
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onTableChange = (pagination, filters, sorter) => {
    if (filters.status) {
      if (filters.status.length === 0 || filters.status.length === 2) {
        this.setState({ dataSource: this.originalDataSource });
      } else if (filters.status.length > 0) {
        var filter = filters.status[0];
        var ds = [];
        var flag;
        if (filter === "Valid") {
          flag = false;
        } else {
          flag = true;
        }
        for (var i = 0; i < this.originalDataSource.length; i++) {
          var obj = this.originalDataSource[i];
          if (obj.disabled === flag) {
            ds.push(obj);
          }
          this.setState({ dataSource: ds });
        }
      }
    } else {
      this.setState({ dataSource: this.originalDataSource });
    }
  };

  renderList() {
    const { common } = this.props;

    let columns = [
      // 名前
      {
        title: localize.Name,
        dataIndex: "displayName",
        key: "displayName",
        ...this.getColumnSearchProps("displayName", localize.Name),
      },

      // メールアドレス
      {
        title: localize.MailAddress,
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email", localize.MailAddress),
      },

      // 管理者
      {
        title: localize.Administrator,
        dataIndex: "isAdmin",
        key: "isAdmin",
        align: "center",
        filters: [
          { text: "権限あり", value: true },
          { text: "権限なし", value: false },
        ],
        render: (text, record) => (record.isAdmin ? <SafetyOutlined style={{ color: blue.primary }} /> : null),
        onFilter: (value, record) => record.isAdmin === value,
      },

      // ステータス
      {
        title: localize.Status,
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 120,
        filters: [
          { text: localize.Valid, value: false },
          { text: localize.Invalid, value: true },
        ],
        render: (text, record) => (record.disabled ? <StopOutlined style={{ color: "#ff0000" }} /> : null),
        onFilter: (value, record) => record.disabled === value,
      },
      // 詳細ボタン
      {
        title: localize.Action,
        dataIndex: "detail",
        key: "detail",
        width: 120,
        render: (text, record) => <Button onClick={() => this.onClick(record)}>{localize.Detail}</Button>,
      },
    ];

    switch (common.collaborationService) {
      // MicroSoftオブジェクトID
      case "teams":
        columns.splice(2, 0, {
          title: localize.MicroSoftObjectId,
          dataIndex: "microsoftObjectId",
          key: "microsoftObjectId",
          ...this.getColumnSearchProps("microsoftObjectId", localize.MicroSoftObjectId),
        });
        break;

      // WowTalk ID
      case "wowtalk":
        columns.splice(2, 0, {
          title: localize.WowTalkID,
          dataIndex: "wowTalkAccount",
          key: "wowTalkAccount",
          ...this.getColumnSearchProps("wowTalkAccount", localize.WowTalkID),
        });

        break;
      default:
        break;
    }

    return (
      <div>
        <Card style={{ margin: "16px" }} extra={this.renderButtons()}>
          {this.renderUserForm()}
          {this.renderSyncResults()}
          <Spin size="large" spinning={this.props.loading.state}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              pagination={2}
              // onChange={this.onTableChange}
              rowKey={(record) => record.uid}
              locale={{ emptyText: " " }}
            />
          </Spin>
        </Card>
      </div>
    );
  }

  componentDidMount() {
    this.reloadData();
  }

  render() {
    // console.log(`UserList Rendering`);
    return this.renderList();
  }
}

// container component
const mapStateToProps = (state) => {
  return {
    loading: getLoadingState(state),
    settingAdvanceDomains: getAdvanceSelector(state),
    common: getCommonSelector(state),
    corporationId: getCorporationIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations, ...loadingOperations }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserList);
UserList.whyDidYouRender = false;
