import React from "react";
import { LockOutlined, UserOutlined, BankOutlined } from "@ant-design/icons";
import { Input, Button, Card, Layout, message, Spin, Form, Image } from "antd";
import { withRouter } from "react-router-dom";
import "../assets/common.css";
import "../assets/loginform.css";
import Global from "../models/Global";
import { IndexedDBHelpers } from "helpers";

const localize = Global.localize;
const { Meta } = Card;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isInitializing: true,
      versionInfo: "",
      tenantId: "",
    };

    this.formRef = React.createRef();
    this.initFirebaseAuth = this.initFirebaseAuth.bind(this);
  }

  initFirebaseAuth() {
    const auth = Global.auth;
    return new Promise((resolve) => {
      var unsubscribe = auth.onAuthStateChanged((user) => {
        // user オブジェクトを resolve
        resolve(user);

        // 登録解除
        unsubscribe();
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) {
      return true;
    }

    if (this.state.isInitializing !== nextState.isInitializing) {
      return true;
    }

    if (this.state.loading !== nextState.loading) {
      return true;
    }

    if (this.state.loginType !== nextState.loginType) {
      return true;
    }

    if (this.state.versionInfo !== nextState.versionInfo) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    // console.log(`LoginForm ComponentDidMount`)
    // const auth = Global.auth;
    // auth.tenantId = "dev04-qtmrq"
    // const currentUser = auth.currentUser;
    // console.log(`auth`, auth)
    // console.log(`auth.currentUser`, auth.currentUser)
    // if (currentUser) {
    //   this.props.history.push({
    //     pathname: "/main",
    //   });
    // }
  }

  componentDidMount() {
    // const db = Global.firestore;
    const auth = Global.auth;
    var self = this;

    // IndexedDBHelpers.getTable({ dbName: "wowdeskLocalStorageDb", tableName: "loginUser" })
    //   .then(async (indexedDBTables) => {
    // const corporationId = indexedDBTables[0].corporationId;

    // this.setState({ isInitializing: true });

    // auth.onAuthStateChanged(async (user) => {
    //   var userData;

    //   if (user) {
    //     // success(ログイン)
    //     const uid = user.uid;
    //     // userData = await db.doc(`${corporationId}/db/users/${uid}`).get();
    //     // if (user && userData.data().is_admin) {
    //     self.props.history.push("/main");
    //     // } else {
    //     //   self.setState({ isInitializing: false });
    //     // }
    //   } else {
    //     // error(ログアウト)
    //     self.setState({ isInitializing: false });
    //   }
    // });

    this.initFirebaseAuth().then(() => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        this.props.history.push({
          pathname: "/main",
        });
      }
    });

    self.setState({ isInitializing: false });
    this.renderFetchVersion();
    // })
    // .catch(async (error) => {
    //   // error(ログアウト)
    //   self.setState({ isInitializing: false });
    // });
  }

  onLogin = (values) => {
    const self = this;
    const auth = Global.auth;
    const db = Global.firestore;
    const func = Global.func;
    const getTenantId = func.httpsCallable("setting-getTenantId");

    const { corporationId, userName, password } = values;

    self.setState({ loading: true });

    // findCorporationId({ corporationId: corporationId })
    //   .then((findCorporationIdResult) => {
    // const { code, result } = findCorporationIdResult.data;
    // if (code === 200) {
    getTenantId({ corporationId: corporationId }).then((getTenantIdResult) => {
      const { code, result: tenantId } = getTenantIdResult.data;
      if (code === 200) {
        auth.tenantId = tenantId;
        this.setState({ tenantId: tenantId, corporationId: corporationId });
        auth
          .signInWithEmailAndPassword(userName, password)
          .then(async (userCredential) => {
            const uid = userCredential.user.uid;
            const userData = await db.doc(`${corporationId}/db/users/${uid}`).get();

            if (userData.data().is_admin) {
              // Success
              // IndexedDB作成

              await IndexedDBHelpers.createTable({
                dbName: "wowdeskLocalStorageDb",
                tableName: "user",
                key: "login",
                values: {
                  userName: userName,
                  corporationId: corporationId,
                },
              });
              this.props.history.push({
                pathname: "/main",
                state: {
                  corporationId: corporationId,
                },
              });
            } else {
              auth.signOut().then(() => {
                self.setState({ loading: false });
                message.warning(localize.MesUserNotHaveAdminAccess);
              });
            }
          })
          .catch((error) => {
            if (error.code === "auth/user-not-found") {
              message.warning(localize.UserNotFound);
            } else if (error.code === "auth/user-disabled") {
              message.warning(localize.AuthUserDisabled);
            } else if (error.code === "auth/wrong-password") {
              message.warning(localize.UserLoginIsPasswordFailed);
            } else if (error.code === "auth/invalid-email") {
              message.warning(localize.PleaseInputCorrectMailAddress);
            } else {
              console.error(error);
            }
            self.setState({ loading: false });
          });
      } else if (code === 501) {
        message.warning(localize.TenantIdIsNotExist);
        self.setState({ loading: false });
      }
    });
    // } else {
    //   console.error("findCorporationId error", result);
    //   message.warning(localize.PleaseCorrectMail);
    // }
    // })
    // .catch((error) => {
    //   console.error("findCorporationId error", error);
    // });
  };

  renderFetchVersion = async () => {
    const locationUrl = window.location.origin;
    const fetchUrl = `${locationUrl}/version.json`;
    const res = await fetch(fetchUrl);
    const data = await res.json();

    this.setState({
      versionInfo: data.version,
    });
  };

  renderLoginForm() {
    return (
      <Layout className="Common LoginForm-Center" style={{ backgroundColor: "#333" }}>
        <Card
          type="inner"
          style={{ width: 350, height: 360 }}
          // headStyle={{ color: "#FFF" }}
          bordered={false}
        >
          <div style={{ textAlign: "center", paddingBottom: "15px", paddingTop: "10px" }}>
            <Image src={`${process.env.PUBLIC_URL}/wowdesk_logo.png`} preview={false} />
            <br />
            <span style={{ color: "#7B7C7D" }}>{localize.ManagementScreen}</span>
          </div>
          <Form onFinish={this.onLogin} className="login-form" ref={this.formRef}>
            {/* 法人Id */}
            <Form.Item name="corporationId" rules={[{ required: true, message: localize.MesEnterCorporationID }]}>
              <Input
                prefix={<BankOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={localize.CorporationId}
                disabled={this.state.loading}
              />
            </Form.Item>

            {/* ID(メールアドレス) */}
            <Form.Item name="userName" rules={[{ required: true, message: localize.MesEnterYourID }]}>
              <Input prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="ID" disabled={this.state.loading} />
            </Form.Item>

            {/* パスワード */}
            <Form.Item name="password" rules={[{ required: true, message: localize.MesEnterYourPassword }]}>
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder={localize.Password}
                disabled={this.state.loading}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button type="primary" loading={this.state.loading} htmlType="submit" className="login-form-button">
                  {localize.Login}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <Meta description={`Version: ${this.state.versionInfo}`} style={{ textAlign: "center" }} />
        </Card>
      </Layout>
    );
  }

  renderLoading() {
    return (
      <Layout className="Common Initial" style={{ backgroundColor: "#333" }}>
        <Spin size="large" />
      </Layout>
    );
  }

  render() {
    if (this.state.isInitializing) {
      return this.renderLoading();
    } else {
      return this.renderLoginForm();
    }
  }
}

export default withRouter(LoginForm);
