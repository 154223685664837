import { hideLoadingAction, showLoadingAction } from "./actions";

export const hideLoading = () => {
  return async (dispatch) => {
    dispatch(hideLoadingAction());
  };
};

export const showLoading = () => {
  return async (dispatch) => {
    dispatch(showLoadingAction());
  };
};
