import * as Actions from "./actions";
import initialState from "store/initialState";

export const SettingReducer = (state = initialState.settings, action) => {
  switch(action.type) {
    case Actions.FETCH_SETTINGS:
      return {
        ...state,
        ...action.payload
      }
      default:
        return state
  }
}