import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";

import config from "./config";

import ja from "./languages/ja";

if (window.firebase === undefined) {
  const app = firebase.initializeApp(config);
  // const perf = firebase.performance();

  // const firestore = ;
  // const settings = {/* your settings... */ timestampsInSnapshots: true};

  window.firebase = firebase;
  window.firestore = firebase.firestore();
  // window.firestore.settings(settings);

  window.auth = firebase.auth();

  window.func = app.functions("asia-northeast1"); //.functions("us-central1");//"asia-northeast1");

  // if(process.env.REGION)
  // {
  //     window.region = process.env.REGION;
  //     window.func = firebase.app().functions(process.env.REGION);
  // }
  // else
  // {
  //     window.func = firebase.functions();
  // }

  if (process.env.NODE_ENV === "development") {
    app.functions().useEmulator("localhost", 5001);
  }

  window.storage = firebase.storage();
}

var global = {};
global.firebase = window.firebase;
global.firestore = window.firestore;
global.auth = window.auth;
global.func = window.func;
global.storage = window.storage;
global.localize = ja;

export default global;
