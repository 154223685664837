import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Global from "../../models/Global";
import { MailPostConfirm, ModalFooter } from "./index";
import moment from "moment";

// import selector
import { getLoadingState } from "../../reducks/loading/selectors";
import { getCorporationIdSelector } from "reducks/settings/selectors";

// antd
import { ConfigProvider, DatePicker, Form, Input, Modal, Select, Spin } from "antd";
import { CloseOutlined, FormOutlined, MailOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import ja_JP from "antd/lib/locale-provider/ja_JP";

const { Item } = Form;
const { TextArea } = Input;

const db = Global.firestore;
const localize = Global.localize;
const usersRef = (getCorporationIdSelector) => db.doc(`${getCorporationIdSelector}/db/users`);

const VisitorModal = React.memo((props) => {
  const selector = useSelector((state) => state);
  const loadingState = getLoadingState(selector);
  const corporationId = getCorporationIdSelector(selector);
  const [form] = Form.useForm();
  const { company, name, mail, receivers, date, memo, id } = props.record;
  const keyLabels = [];

  useEffect(() => {
    async function fetch() {
      if (Object.keys(props.record).length > 0) {
        form.resetFields();
        if (receivers) {
          for (let i = 0; i < receivers.length; i++) {
            let obj = { label: receivers[i], key: i };
            try {
              const snapshot = await usersRef(corporationId).get();
              if (snapshot && snapshot.exists) {
                const data = snapshot.data().displayName;
                obj.label = data;
              } else {
                obj.label = "未設定";
              }
              keyLabels.push(obj);
            } catch (ex) {
              console.log(ex.toString());
            }
          }
          form.setFieldsValue({
            receivers: keyLabels,
          });
        }

        form.setFieldsValue({
          company: company,
          name: name,
          mail: mail,
          date: date ? moment(date) : null,
          memo: memo,
        });
      }
    }

    fetch();
    // 初期値でidが空になる為
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // console.log(`props`, props);
  return (
    <>
      <ConfigProvider>
        <Modal
          forceRender={true}
          title={localize.VisitorView}
          visible={props.visible}
          footer={<ModalFooter id={id} handleModalVisible={props.handleModalVisible} form={form} />}
          closeIcon={
            <div onClick={props.handleModalVisible}>
              <CloseOutlined />
            </div>
          }
        >
          <Form form={form}>
            {/* 会社名 */}
            <Item name={"company"}>
              <Input
                key={"inputCompany"}
                type={"text"}
                name={"company"}
                placeholder={"会社名"}
                disabled={true}
                prefix={<TeamOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Item>
            {/* 名前 */}
            <Item name={"name"}>
              <Input
                key={"inputName"}
                type={"text"}
                name={"name"}
                placeholder={"名前"}
                addonAfter={"様"}
                disabled={true}
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Item>
            {/* メールアドレス */}
            <Item name={"mail"}>
              <Input
                key={"inputMail"}
                type={"text"}
                name={"mail"}
                placeholder={"メールアドレス"}
                disabled={true}
                prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Item>
            {/* 通知先 */}
            <Item name={"receivers"}>
              <Select
                labelInValue
                suffixIcon={"user"}
                mode={"multiple"}
                size={"default"}
                placeholder={localize.NumberingReceivers}
                disabled={true}
                notFoundContent={loadingState ? <Spin size="small" /> : null}
                filterOption={false}
                optionFilterProp={"title"}
                style={{ width: "100%" }}
              ></Select>
            </Item>
            {/* 日付 */}
            <Item name={"date"}>
              <DatePicker
                showTime={{ format: "HH:mm" }}
                locale={ja_JP}
                disabled={true}
                allowClear={false}
                format={"YYYY/MM/DD HH:mm"}
                placeholder={localize.VisitDate}
              />
            </Item>
            {/* メモ */}
            <Item name={"memo"} rules={[{ max: 100, message: localize.InputMax100Length }]}>
              <TextArea
                prefix={<FormOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                key={"inputMemo"}
                type={"text"}
                name={"memo"}
                autoSize={{ minRows: 2, maxRows: 6 }}
                disabled={false}
                placeholder={"メモ"}
              />
            </Item>
            {/* 通知 */}
            <MailPostConfirm sent={props.record.sent} editMode={props.editMode} mailPostButtonVisible={props.mailPostButtonVisible} />
          </Form>
        </Modal>
      </ConfigProvider>
    </>
  );
});

export default VisitorModal;
