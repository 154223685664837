import Global from "../models/Global";

const db = Global.firestore;
const commonRef = db.doc("settings/common");

const SettingHelpers = {
  getLoginType: async () => {
    const doc = await commonRef.get();
    let type;

    if (doc.exists) {
      const data = doc.data();
      if (data["login_info"] && data["login_info"]["type"]) {
        type = data["login_info"]["type"];
      }

      if (type && type === "teams") {
        return { loginType: 2 };
      } else if (type && type === "wowtalk") {
        return { loginType: 1 };
      }
    }

    return { loginType: 0 };
  },
};

export default SettingHelpers;
