export const DELETE_HISTORYLIST_ITEM = "DELETE_HISTORYLIST_ITEM"
export const deleteHistoryListItemAction = (history) => {
  return{
    type: DELETE_HISTORYLIST_ITEM,
    payload: {
      list: history.list
    }
  }
}

export const FETCH_HISTORYLIST = "FETCH_HISTORYLIST"
export const fetchHistoryListAction = (history) => {
  return{
    type: FETCH_HISTORYLIST,
    payload: {
      list: history.list
    }
  }
}

export const SAVE_HISTORYLIST_ITEM = "SAVE_HISTORYLIST_ITEM"
export const saveHistoryListItemAction = (history) => {
  return{
    type: SAVE_HISTORYLIST_ITEM,
    payload: {
      list: history.list
    }
  }
}