import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoadingState } from "reducks/loading/selectors";
import { getHistoryList } from "reducks/history/selectors";
import Global from "models/Global";

// import antd
import { Button } from "antd";

// import operation
import { deleteHistoryListItem, saveHistoryListItem } from "reducks/history/operations";

const localize = Global.localize;

const ModalFooter = (props) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const loadingState = getLoadingState(selector);
  const historyList = getHistoryList(selector);

  const { form, handleModalVisible, id } = props;
  const historyListFindItem = historyList.find((item) => item.id === id);

  const onClickUpdateButton = useCallback(
    (id) => {
      const formMemo = form.getFieldValue("memo");
      if (historyListFindItem.memo !== formMemo) {
        form.validateFields().then(() => {
          dispatch(saveHistoryListItem(id, formMemo));
        });
      }
      handleModalVisible();
    },
    // AntDesignのuseFormを仕様している影響でidにundefinedが入る為
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  const onClickDeleteButton = useCallback(
    (id) => {
      dispatch(deleteHistoryListItem(id));
      handleModalVisible();
    },
    // AntDesignのuseFormを仕様している影響でidにundefinedが入る為
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  return (
    <div>
      <Button type="primary" onClick={() => onClickUpdateButton(id)} loading={loadingState.state}>
        {localize.Update}
      </Button>
      <Button type="danger" onClick={() => onClickDeleteButton(id)} loading={loadingState.state}>
        {localize.Delete}
      </Button>
      <Button onClick={handleModalVisible}>{localize.Cancel}</Button>
    </div>
  );
};

export default ModalFooter;
