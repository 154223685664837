const initialState = {
  devices: {
    settings: [],
  },
  loading: {
    state: false,
  },
  visitors: {
    history: {
      list: [],
    },
  },
  settings: {
    advance: {},
    common: {},
    corporationId: null,
  },
};

export default initialState;
