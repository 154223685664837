import { createStore as reduxCreateStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

// import reducers
import { DevicesReducer } from "reducks/devices/reducers";
import { HistoryReducer } from "reducks/history/reducers";
import { LoadingReducer } from "reducks/loading/reducers";
import { SettingReducer } from "reducks/settings/reducers";

export default function createStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  let middleWares = [thunk];
  if (process.env.NODE_ENV === "development") {
    const logger = createLogger({
      collapsed: true,
      diff: false,
    });
    middleWares.push(logger);
  }

  const visitorsReducers = combineReducers({
    history: HistoryReducer,
  });

  return reduxCreateStore(
    combineReducers({
      devices: DevicesReducer,
      loading: LoadingReducer,
      visitors: visitorsReducers,
      settings: SettingReducer
    }),
    composeEnhancers(applyMiddleware(...middleWares))
  );
}
