import React from "react";

// import antd
import { Button, Checkbox, Form } from "antd";
import { CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";

const { Item } = Form;

const MailPostConfirm = (props) => {
  const { editMode, mailPostButtonVisible, sent } = props;

  const renderMailStatusFlag = (sent, mailPostButtonVisible) => {
    if (sent) {
      return (
        <span>
          <CheckCircleFilled style={{ color: "#27ae60" }} />
          <span style={{ marginLeft: "6px" }}>通知メール送信済</span>
          {mailPostButtonVisible ? (
            // <Button type="dashed" style={{ marginLeft: "8px" }} onClick={() => this.onSendMail(this.state.currentId)} loading={this.state.loading}>
            <Button type="dashed" style={{ marginLeft: "8px" }}>
              再送
            </Button>
          ) : null}
        </span>
      );
    }

    return (
      <span>
        <InfoCircleFilled style={{ color: "gray" }} />
        <span style={{ marginLeft: "6px" }}>通知メール未送信</span>
        {mailPostButtonVisible ? (
          // <Button type="dashed" style={{ marginLeft: "8px" }} onClick={() => this.onSendMail(this.state.currentId)} loading={this.state.loading}>
          <Button type="dashed" style={{ marginLeft: "8px" }}>
            送信
          </Button>
        ) : null}
      </span>
    );
  };

  return (
    <>
      <Item rules={[{ required: false }]} style={{ marginBottom: "0px" }}>
        {editMode ? renderMailStatusFlag(sent, mailPostButtonVisible) : <Checkbox disabled={sent}>番号お知らせメール送信</Checkbox>}
      </Item>
    </>
  );
};

export default MailPostConfirm;
