import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Table, Spin, Button, Card } from "antd";
import Global from "../models/Global";
import UserHelpers from "../helpers/UserHelpers";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import "../assets/common.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingOperations from "reducks/settings/operations";
import * as loadingOperations from "reducks/loading/operations";
import { getLoadingState } from "reducks/loading/selectors";
import { getCommonSelector, getCorporationIdSelector, getAdvanceSelector } from "reducks/settings/selectors";

const localize = Global.localize;
const db = Global.firestore;

class Export extends React.Component {
  columns = [
    {
      title: localize.MailAddress,
      dataIndex: "email",
      key: "email",
    },
    {
      title: localize.Name,
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title:
        this.props.common.collaborationService === "wowtalk"
          ? localize.WowTalkID
          : this.props.common.collaborationService === "teams" && localize.MicroSoftObjectId,
      dataIndex:
        this.props.common.collaborationService === "wowtalk"
          ? "wowTalkAccount"
          : this.props.common.collaborationService === "teams" && "microsoftObjectId",
      key:
        this.props.common.collaborationService === "wowtalk"
          ? "wowTalkAccount"
          : this.props.common.collaborationService === "teams" && "microsoftObjectId",
    },
  ];

  state = {
    loading: false,
    dataSource: [],
    dataExport: [],
  };

  constructor(props) {
    super(props);

    this.reloadData = this.reloadData.bind(this);
    this.renderExportButton = this.renderExportButton.bind(this);
    this.onExportUser = this.onExportUser.bind(this);
  }

  renderLoading() {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Spin size="large" />
      </div>
    );
  }

  findParent(array, groupId) {
    if (!groupId) {
      return "";
    }
    for (var i = 0; i < array.length; i++) {
      var dict = array[i];
      var key = dict["group_id"];

      if (key === groupId) {
        return this.dict;
      }
    }

    return {};
  }

  reloadData() {
    var self = this;
    var arrData = [];
    this.setState({ loading: true });

    this.props.actions.fetchSettings().then(() => {
      const { corporationId, common } = this.props;
      const { collaborationService } = common;

      UserHelpers.loadUserList(corporationId)
        .then((resData) => {
          self.setState({ dataSource: resData });
        })
        .then(async () => {
          var departmentRef = await db.doc(`${corporationId}/db/departments/department_information`).get();
          if (departmentRef.exists) {
            var department = departmentRef.data().synced_data;
            var groupDict = {};

            for (var i = 0; i < department.length; i++) {
              groupDict[department[i].group_id] = department[i];
            }

            for (var j = 0; j < self.state.dataSource.length; j++) {
              var data = self.state.dataSource[j];
              let wowTalkGroup = null;
              let teamsGroup = null;

              if (data.wowTalkGroup) {
                const groups = groupDict[data["wowTalkGroup"]];
                wowTalkGroup = groups ? (groups["hierarchy"] === "" ? groups["group_name"] : groups["hierarchy"] + "/" + groups["group_name"]) : "";
              }

              if (data.teamsGroup) {
                const channel = groupDict[data["teamsGroup"]];
                const team = groupDict[channel.parent_id];

                teamsGroup = `${team.group_name}/${channel.group_name}`;
              }

              arrData.push({
                email: data.email,
                displayName: data.displayName,
                teamsGroup: teamsGroup,
                wowTalkAccount: collaborationService === "wowtalk" && data.wowTalkAccount,
                wowTalkGroup: wowTalkGroup,
                isAdmin: data.isAdmin,
              });
            }
          } else {
            arrData = [...self.state.dataSource];
          }

          self.setState({ dataExport: arrData, loading: false });
        });
    });
  }

  componentDidMount() {
    this.reloadData();
  }

  onExportUser() {
    const fileName = "users.csv";
    var itemExport = [];
    const { common } = this.props;
    const { collaborationService } = common;

    for (let currentItem = 0; currentItem < this.state.dataExport.length; currentItem++) {
      const item = {
        email: this.state.dataExport[currentItem].email,
        displayName: this.state.dataExport[currentItem].displayName,
        isAdmin: this.state.dataExport[currentItem].isAdmin ? 1 : 0,
      };

      if (collaborationService === "wowtalk") {
        item.linkId = this.state.dataExport[currentItem].wowTalkAccount;
        item.department = this.state.dataExport[currentItem].wowTalkGroup;
      } else if (collaborationService === "teams") {
        item.department = this.state.dataExport[currentItem].teamsGroup;
      }

      itemExport.push(item);
    }

    const delimiterString = Papa.unparse(itemExport, {
      columns:
        collaborationService === "wowtalk"
          ? ["email", "displayName", "linkId", "department", "isAdmin"]
          : collaborationService === "teams" && ["email", "displayName", "department", "isAdmin"],
      quotes: true,
      quoteChar: '"',
      delimiter: ",",
      header: true,
    });

    const strArray = Encoding.stringToCode(delimiterString);
    const convertedArray = Encoding.convert(strArray, "SJIS", "UNICODE");
    const UintArray = new Uint8Array(convertedArray);
    const blobUrl = new Blob([UintArray], { type: "text/csv" });

    const blob = blobUrl;
    const aTag = document.createElement("a");

    aTag.download = fileName;

    if (window.navigator.msSaveBlob) {
      // for IE
      window.navigator.msSaveBlob(blob, aTag.download);
    } else if (window.URL && window.URL.createObjectURL) {
      // for Firefox
      aTag.href = window.URL.createObjectURL(blob);

      document.body.appendChild(aTag);

      aTag.click();

      document.body.removeChild(aTag);
    } else if (window.webkitURL && window.webkitURL.createObject) {
      // for Chrome
      aTag.href = (window.URL || window.webkitURL).createObjectURL(blob);

      aTag.click();
    } else {
      // for Safari
      window.open(`data:type/csv;base64,${window.Base64.encode(this.state.content)}`, "_blank");
    }
  }

  renderExportButton = () => (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <Button icon={<UploadOutlined />} type="primary" onClick={this.onExportUser}>
        {localize.ExportButtonImport}
      </Button>
    </div>
  );

  renderList() {
    return (
      <div>
        <Card style={{ margin: "16px" }} extra={this.renderExportButton()} title={localize.ExportTitle}>
          <Spin size="large" spinning={this.state.loading}>
            <Table dataSource={this.state.dataSource} columns={this.columns} pagination={2} rowKey={(record) => record.uid} />
          </Spin>
        </Card>
      </div>
    );
  }

  render() {
    // console.log(`this.state.dataExport`, this.state.dataExport);
    return this.renderList();
  }
}

// container component
const mapStateToProps = (state) => {
  return {
    loading: getLoadingState(state),
    advance: getAdvanceSelector(state),
    common: getCommonSelector(state),
    corporationId: getCorporationIdSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations, ...loadingOperations }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Export);
