import React from "react";
import Global from "../models/Global";
import { LinkOutlined, LockOutlined, ProfileOutlined, SafetyOutlined, UserOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Input, Button, TreeSelect, message, Select, Form } from "antd";
import UserHelpers from "../helpers/UserHelpers";
import { connect } from "react-redux";
import { getCommonSelector, getCorporationIdSelector, getAdvanceSelector } from "reducks/settings/selectors";

const localize = Global.localize;
const { Option } = Select;

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      // フォームの各ボタンのdisabledを有効にする
      creating: false,
      updating: false,
      deleting: false,
      // 更新時のローディング中にInputに値を入れる為に使用する
      editData: null,
    };

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.renderCreateButtons = this.renderCreateButtons.bind(this);
    this.renderModifyButtons = this.renderModifyButtons.bind(this);
    this.onCheckExistWowTalkAccount = this.onCheckExistWowTalkAccount.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.visible !== nextProps.visible) {
      return true;
    } else if (this.state.loading !== nextState.loading) {
      return true;
    } else if (this.state.visible !== nextState.visible) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    // AntDesignではformのinitialValueを動的に制御することができないので、直接DOMにデータをセットする
    if (this.state.editData) {
      this.formRef.current.setFieldsValue(this.state.editData);
    } else if (this.props.editData) {
      this.formRef.current.setFieldsValue(this.props.editData);
    }
  }

  // 削除
  onDelete(e) {
    e.preventDefault();
    this.setState({ loading: true, deleting: true });

    const { setFieldsValue } = this.formRef.current;
    const { corporationId } = this.props;
    const uid = this.props.editData.uid;
    var self = this;

    const modal = Modal.confirm();

    modal.update({
      title: localize.UserDelete,
      content: this.props.editData.displayName + localize.ConfirmUserDelete,
      transitionName: "am-slide-down",
      cancelText: localize.Cancel,
      onOk: (e) => {
        Modal.destroyAll();

        UserHelpers.callDeleteUser({
          uid: uid,
          corporationId: corporationId,
        })
          .then((e) => {
            message.info(localize.UserDeletedSuccess);

            self.setState({
              loading: false,
              deleting: false,
            });

            self.onConfirm(null, uid);
            setFieldsValue({
              email: "",
              displayName: "",
              pronounce: "",
              password: "",
              repassword: "",
              linkId: "",
              group: undefined,
              isAdmin: undefined,
            });
          })
          .catch((error) => {
            console.error(error);
            this.setState({ loading: false, deleting: false });
            message.warn(localize.UserDeletedFailed);
            Modal.destroyAll();
          });
      },
      onCancel: (e) => {
        self.setState({
          loading: false,
          deleting: false,
        });

        Modal.destroyAll();
      },
    });
  }

  // 更新
  onUpdate = (e) => {
    e.preventDefault();
    const { getFieldValue, setFieldsValue, validateFields } = this.formRef.current;

    this.setState({
      loading: true,
      updating: true,
    });

    const getPronounce = getFieldValue("pronounce") ? getFieldValue("pronounce").replace(/　/g, " ") : null,
      getEmail = getFieldValue("email"),
      getDisplayName = getFieldValue("displayName"),
      getwowTalkAccount = getFieldValue("wowTalkAccount") ? getFieldValue("wowTalkAccount") : null,
      getWowtalkGroup = getFieldValue("wowTalkGroup") ? getFieldValue("wowTalkGroup") : null,
      getTeamsGroup = getFieldValue("teamsGroup") ? getFieldValue("teamsGroup") : null,
      getIsAdmin = getFieldValue("isAdmin"),
      getDisable = getFieldValue("disabled"),
      getPassword = getFieldValue("password"),
      getRePassword = getFieldValue("repassword");

    var self = this;
    const { corporationId, common } = this.props;

    const updatedUserBody = {
      uid: this.props.editData.uid,
      email: getEmail,
      displayName: getDisplayName,
      isAdmin: getIsAdmin,
      pronounce: getPronounce,
      disabled: getDisable,
      wowTalkAccount: getwowTalkAccount,
      wowTalkGroup: getWowtalkGroup,
      teamsGroup: getTeamsGroup,
      collaborationService: common.collaborationService,
      tenantId: common.tenantId,
      corporationId: corporationId,
    };

    this.setState({
      editData: updatedUserBody,
    });

    UserHelpers.callUpdateUser(updatedUserBody)
      .then((result) => {
        const resultCode = result.data.code;

        if (resultCode === 200) {
          Modal.destroyAll();

          self.onConfirm({
            uid: this.props.editData.uid,
            email: getEmail,
            pronounce: getPronounce,
            displayName: getDisplayName,
            disabled: getDisable,
            isAdmin: getIsAdmin,
            wowTalkAccount: getwowTalkAccount,
            wowTalkGroup: getWowtalkGroup,
            teamsGroup: getTeamsGroup,
          });
          message.success(localize.UserUpdatedSuccess);

          setFieldsValue({
            email: "",
            displayName: "",
            pronounce: "",
            password: "",
            repassword: "",
            linkId: "",
            wowTalkGroup: undefined,
            teamsGroup: undefined,
            isAdmin: undefined,
            disabled: undefined,
          });

          self.setState({
            loading: false,
            updating: false,
            editData: null,
          });
        }
      })
      .catch((result) => {
        console.error(result);
        self.setState({
          loading: false,
          updating: false,
        });
      });
  };

  // 新規作成
  onAdd = (e) => {
    e.preventDefault();
    const { getFieldValue, validateFields } = this.formRef.current;
    const { corporationId, common } = this.props;

    var self = this;
    validateFields()
      .then((value) => {
        this.setState({
          loading: true,
          creating: true,
        });

        UserHelpers.callCreateUser({
          email: getFieldValue("email"),
          displayName: getFieldValue("displayName"),
          pronounce: getFieldValue("pronounce") ? getFieldValue("pronounce").replace(/　/g, " ") : null,
          password: getFieldValue("password"),
          isAdmin: value.isAdmin,
          wowTalkAccount: getFieldValue("wowTalkAccount"),
          wowTalkGroup: getFieldValue("wowTalkGroup"),
          teamsGroup: getFieldValue("teamsGroup"),
          tenantId: common.tenantId,
          corporationId: corporationId,
        })
          .then((result) => {
            const { code } = result.data;
            if (code === 200) {
              message.success(localize.UserCreatedSuccess);
            } else {
              message.error(localize.UserCreatedFailed);
            }

            self.setState({
              loading: false,
              creating: false,
            });

            self.onConfirm();
            this.formRef.current.setFieldsValue({
              email: "",
              displayName: "",
              pronounce: "",
              password: "",
              repassword: "",
              linkId: "",
              wowTalkGroup: undefined,
              teamsGroup: undefined,
              isAdmin: undefined,
              disabled: undefined,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);

        self.setState({
          loading: false,
          creating: false,
        });
      });
  };

  // キャンセル
  onCancel = (e) => {
    this.formRef.current.setFieldsValue({
      email: "",
      displayName: "",
      pronounce: "",
      password: "",
      repassword: "",
      wowTalkAccount: "",
      wowTalkGroup: undefined,
      teamsGroup: undefined,
      isAdmin: undefined,
      disabled: undefined,
    });

    this.setState({
      visible: false,
      editData: null,
    });

    this.props.onCancel();
  };

  // ローカルStateの更新
  onConfirm = (data, key) => {
    if (data) {
      // 更新時
      this.props.onConfirm(data);
    } else if (key) {
      // 削除時
      this.props.onDelete(key);
    } else {
      // 新規作成時
      this.props.onReloadData();
      this.props.onCancel();
    }

    // setTimeout(() => {
    this.setState({
      visible: false,
    });
    // }, 100);
  };

  onConfirmPassword = (rule, value, callback) => {
    const { getFieldValue } = this.formRef.current;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject(localize.PasswordAndConfrimNotPair);
    } else {
      return Promise.resolve();
    }
  };

  onCheckExistEmail = (rule, value, callback) => {
    const checkExistValue = this.props.listUsers.some((el) => el.email === value);

    if (value && checkExistValue) {
      return Promise.reject(localize.UserCreatedFailed);
    } else {
      return Promise.resolve();
    }
  };

  onCheckExistWowTalkAccount = (rule, newWowTalkAccount, callback) => {
    if (newWowTalkAccount && this.props.listUsers.some((el) => el.wowTalkAccount === newWowTalkAccount)) {
      return Promise.reject(localize.UserUpdatedFailedWowtalkID);
    } else {
      return Promise.resolve();
    }
  };

  onForgotPassword = (e) => {
    const { getFieldValue } = this.formRef.current;
    const { common } = this.props;
    const tenantId = common.tenantId;
    var email = getFieldValue("email");
    const auth = Global.auth;

    auth.languageCode = "ja";
    auth.tenantId = tenantId;

    if (email) {
      const modal = Modal.confirm();
      modal.update({
        title: localize.ForgotPassword,
        content: email + localize.SendForgotPassword,
        transitionName: "am-slide-down",
        cancelText: localize.Cancel,
        onOk: (e) => {
          auth
            .sendPasswordResetEmail(email)
            .then((result) => {
              message.info(localize.SentForgotPassword);
            })
            .catch((e) => {
              message.warn(e.toString());
            });
          modal.destroy();
        },
        onCancel: (e) => {
          modal.destroy();
        },
      });
    } else {
      Modal.warning({
        title: localize.ForgotPassword,
        content: localize.PleaseInputMailAddress,
      });
    }
  };

  renderModifyButtons() {
    const { loading, updating, deleting } = this.state;
    return (
      <div>
        <Button type="primary" onClick={this.onUpdate} loading={updating} disabled={loading}>
          {localize.Update}
        </Button>
        <Button type="danger" onClick={this.onDelete} loading={deleting} disabled={loading}>
          {localize.Delete}
        </Button>
        <Button onClick={this.onForgotPassword} style={{ color: "#1890ff", backgroundColor: "#ffffff", borderColor: "#1890ff" }} disabled={loading}>
          {localize.PasswordReset}
        </Button>
        <Button onClick={this.onCancel} disabled={loading}>
          {localize.Cancel}
        </Button>
      </div>
    );
  }

  renderCreateButtons() {
    return (
      <div>
        <Button onClick={this.onAdd} type="primary" loading={this.state.loading}>
          {localize.Confirm}
        </Button>
        <Button onClick={this.onCancel}>{localize.Cancel}</Button>
      </div>
    );
  }

  render() {
    // console.log("UserForm rendering");
    const { loading } = this.state;
    const { advance, common, editMode, title, visible, editData } = this.props;
    const { collaborationService } = common;
    let regex = new RegExp();

    if (advance && advance.domains) {
      const { domains } = advance;
      regex = UserHelpers.createRegexFromDomainOfEmail(domains);
    }

    return (
      <Modal
        title={title}
        maskClosable={false}
        visible={visible}
        confirmLoading={loading}
        closable={false}
        onCancel={this.onCancel}
        cancelText={localize.Cancel}
        footer={editMode ? this.renderModifyButtons() : this.renderCreateButtons()}
        forceRender={true}
      >
        <Form className="login-form" ref={this.formRef}>
          {/* メールアドレス */}
          <Form.Item
            style={{ marginBottom: "14px" }}
            name="email"
            rules={[
              { required: true, message: localize.PleaseInputMailAddress },
              { type: "email", message: localize.PleaseInputCorrectMailAddress },
              { validator: this.onCheckExistEmail },
              { pattern: regex, message: localize.InputSupportDomain },
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={localize.MailAddress}
              disabled={editMode && editData && editData.email ? true : false}
            />
          </Form.Item>

          {/* 名前 */}
          <Form.Item style={{ marginBottom: "14px" }} name="displayName" rules={[{ required: true, message: localize.PleaseInputName }]}>
            <Input prefix={<ProfileOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={localize.Name} disabled={loading} />
          </Form.Item>

          {/* フリガナ */}
          <Form.Item style={{ marginBottom: "14px" }} name="pronounce">
            <Input prefix={<ProfileOutlined style={{ color: "rgba(0,0,0,.25)" }} />} placeholder={localize.pronounce} disabled={loading} />
          </Form.Item>

          {/* パスワード */}
          {!editMode && (
            <Form.Item
              style={{ marginBottom: "14px" }}
              name="password"
              rules={[
                { required: true, message: localize.PleaseInputPassword },
                { min: 6, max: 12, message: localize.PleaseCheckPasswordFormat },
              ]}
            >
              <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder={localize.Password}
                disabled={loading}
                autoComplete="on" // コンソールログwarning対応
              />
            </Form.Item>
          )}

          {/* パスワード再確認 */}
          {!editMode && (
            <Form.Item
              style={{ marginBottom: "14px" }}
              name="repassword"
              rules={[{ required: true, message: localize.PleaseInputConfirmPassword }, { validator: this.onConfirmPassword }]}
            >
              <Input
                prefix={<SafetyOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder={localize.ConfirmPassword}
                disabled={loading}
                autoComplete="on" // コンソールログwarning対応
              />
            </Form.Item>
          )}

          {/* Wowtalk ID */}
          {collaborationService === "wowtalk" && (
            <Form.Item
              style={{ marginBottom: "14px" }}
              name="wowTalkAccount"
              rules={[
                { required: false, message: localize.PleaseInputwowTalkAccount },
                { validator: this.onCheckExistWowTalkAccount },
                { pattern: /^[@_\\.-\w]+$/, message: localize.PleaseInputCorrectwowTalkAccount },
              ]}
            >
              <Input
                prefix={<LinkOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder={localize.WowTalkAccount}
                disabled={(editMode && editData) || loading}
              />
            </Form.Item>
          )}

          {/* WowTalk通知グループ */}
          {collaborationService === "wowtalk" && (
            <Form.Item style={{ marginBottom: "14px" }} name="wowTalkGroup">
              <TreeSelect
                treeData={this.props.listDepartments}
                placeholder={localize.NotificationWowTalkGroup}
                multiple={false}
                getPopupContainer={(node) => node.parentNode}
                treeDefaultExpandAll={true}
                allowClear={true}
                disabled={loading}
              />
            </Form.Item>
          )}

          {/* Teams通知グループ */}
          {collaborationService === "teams" && (
            <Form.Item style={{ marginBottom: "14px" }} name="teamsGroup">
              <TreeSelect
                treeData={this.props.listDepartments}
                placeholder={localize.NotificationTeamsGroup}
                multiple={false}
                getPopupContainer={(node) => node.parentNode}
                treeDefaultExpandAll={true}
                allowClear={true}
                disabled={loading}
              />
            </Form.Item>
          )}

          {/* 管理者権限 */}
          <Form.Item style={{ marginBottom: "14px" }} name="isAdmin" rules={[{ required: true, message: localize.SelectAdministratorAuthority }]}>
            <Select style={{ width: 150 }} placeholder={localize.AdministratorAuthority} disabled={loading}>
              <Option value={false}>管理者権限なし</Option>
              <Option value={true}>管理者権限あり</Option>
            </Select>
          </Form.Item>

          {/* アカウント有効・無効 */}
          {editMode && (
            <Form.Item style={{ marginBottom: "0px" }} name="disabled">
              <Select style={{ width: 150 }} placeholder={localize.Status} disabled={loading}>
                <Option value={false}>ステータス有効</Option>
                <Option value={true}>ステータス無効</Option>
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advance: getAdvanceSelector(state),
    corporationId: getCorporationIdSelector(state),
    common: getCommonSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UserForm);
