import Global from "../../models/Global";
import moment from "moment";
import { IndexedDBHelpers } from "helpers";

// import action
import { deleteHistoryListItemAction, fetchHistoryListAction, saveHistoryListItemAction } from "./actions";

// import operation
import { hideLoading, showLoading } from "../loading/operations";

// import antd
import { message } from "antd";

const db = Global.firestore;
const localize = Global.localize;
const devicesRef = (corporationId) => db.collection(corporationId).doc("db").collection("devices");
const visitorsRef = (corporationId) => db.collection(corporationId).doc("db").collection("visitors");

const addHistoyList = (list, index, snapshot, deviceSettings) => {
  let history = snapshot.data();
  const deviceData = deviceSettings.find((item) => item.id === history.device_id);
  const deviceName = history.device_name ? history.device_name : deviceData ? deviceData.name : "削除済";

  const data = {
    id: snapshot.id,
    index: index + 1,
    key: snapshot.id,
    accessDate: "access_date" in history ? history.access_date : "",
    company: !history.company ? "-" : history.company,
    date: "access_date" in history ? moment(history.access_date.toDate()).format("YYYY/MM/DD HH:mm") : "",
    name: "name" in history ? history.name : "",
    number: "number_value" in history ? history.number_value : "-",
    numberValue: "number_value" in history ? history.number_value : "-",
    receivers: history.receivers,
    state: history.state,
    createTime: "timestamp" in history ? moment(history.timestamp.toDate()).format("YYYY/MM/DD HH:mm") : "-",
    mail: history.mail,
    memo: "memo" in history ? history.memo : "",
    creatorName: "creator_name" in history ? history.creator_name : "-",
    sent: "sent" in history ? history.sent : false,
    handler: history.handler,
    handlerName: history.handler_name,
    creatorId: history.creator_id,
    deviceId: history.device_id,
    deviceName: deviceName,
  };

  list.push(data);
};

export const deleteHistoryListItem = (id) => {
  return async (dispatch, getState) => {
    const list = getState().visitors.history.list;
    const corporationId = getState().settings.corporationId;

    await dispatch(showLoading());

    visitorsRef(corporationId)
      .doc(id)
      .delete()
      .then(() => {
        let excludeList = list.filter((item) => item.id !== id);
        let index = 1;

        excludeList.forEach((item) => {
          item.index = index++;
        });

        dispatch(
          deleteHistoryListItemAction({
            list: excludeList,
          })
        );

        message.info(localize.VisitorDeleteSuccess);
      })
      .catch((error) => {
        message.warn(localize.VisitorDeleteFaild);
        throw new Error(error);
      });

    dispatch(hideLoading());
  };
};

export const fetchHistoryList = () => {
  return async (dispatch) => {
    try {
      const deviceSetttingList = [];

      const indexedDBTable = await IndexedDBHelpers.getTable({ dbName: "wowdeskLocalStorageDb", tableName: "user", key: "login" });
      const corporationId = indexedDBTable.corporationId;

      const devicesRefSnapshots = await devicesRef(corporationId).get();
      devicesRefSnapshots.forEach((devicesRefSnapshot) => {
        const data = devicesRefSnapshot.data();
        deviceSetttingList.push({
          id: devicesRefSnapshot.id,
          name: data.name,
          index: data.index,
        });
      });

      dispatch(showLoading());
      visitorsRef(corporationId)
        .where("state", "==", 2)
        .orderBy("access_date", "desc")
        .get()
        .then((snapshots) => {
          const list = [];
          let index = 0;
          snapshots.forEach((snapshot) => {
            addHistoyList(list, index, snapshot, deviceSetttingList);
            index++;
          });

          dispatch(
            fetchHistoryListAction({
              list: list,
            })
          );

          dispatch(hideLoading());
        })
        .catch((error) => {
          throw new Error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const saveHistoryListItem = (id, memo) => {
  return async (dispatch, getState) => {
    let historyList = getState().visitors.history.list;
    const corporationId = getState().settings.corporationId;
    const data = { memo: memo };

    dispatch(showLoading());
    visitorsRef(corporationId)
      .doc(id)
      .set(data, { merge: true })
      .then(() => {
        let findItem = historyList.find((item) => item.id === id);
        findItem.memo = memo;

        dispatch(
          saveHistoryListItemAction({
            list: historyList,
          })
        );
        message.info(localize.VisitorUpdateSuccess);
      })
      .catch((error) => {
        message.warn(localize.VisitorUpdateFaild);
        throw new Error(error);
      });

    dispatch(hideLoading());
  };
};
