import * as Actions from "./actions";
import initialState from "../../store/initialState";

export const HistoryReducer = (state = initialState.visitors.history, action) => {
  switch (action.type) {
    case Actions.DELETE_HISTORYLIST_ITEM:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.FETCH_HISTORYLIST:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.SAVE_HISTORYLIST_ITEM:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
