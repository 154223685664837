import React from 'react';

import { withRouter } from "react-router-dom";
import { Layout, Spin } from 'antd';


import "../assets/common.css";
import "../assets/initial.css";

import Global from '../models/Global'

class Initial extends React.Component {

    componentDidMount(){
        const auth = Global.auth;

        process.nextTick(()=>{
            var user = auth.currentUser;

            if (user) {
                // User is signed in.
                this.props.history.push("/main");
            } 
            else {
                // No user is signed in.
                this.props.history.push("/login");
            }
        });
    }

    render() {
        return (
            <Layout className="Common Initial" style={{ backgroundColor: '#333' }} >

                <Spin size="large" />

            </Layout>
        )
    }
}

// ReactDOM.render(<Initial />, document.getElementById('root'))

export default withRouter(Initial);