import "lib/wdyr";
import React, { useCallback, useEffect, useState } from "react";
import Global from "../models/Global";
import { useDispatch, useSelector } from "react-redux";
import { ExportModal, VisitorModal } from "../components/visitors/index";
import "moment/locale/ja";

// import selector
import { getLoadingState } from "../reducks/loading/selectors";
import { getHistoryList } from "../reducks/history/selectors";

// import action
import { fetchHistoryList } from "../reducks/history/operations";

// import antd
import { Button, Card, ConfigProvider, Spin, Table, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import jaJP from "antd/es/locale/ja_JP";
import { fetchSettingAction } from "reducks/settings/actions";

const localize = Global.localize;

const HistoryList = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const historyList = getHistoryList(selector);
  const loadingState = getLoadingState(selector);

  const [visitorModalVisible, setVisitorModalVisible] = useState(false),
    [exportModalVisible, setExportModalVisible] = useState(false),
    [recordData, setRecordData] = useState(""),
    [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "#",
      width: 80,
      dataIndex: "index",
      key: "index",
      // fixed: 'left', IE11非対応の為
    },
    {
      title: "会社名",
      dataIndex: "company",
      width: 200,
      key: "company",
      // fixed: 'left', IE11非対応の為
      render: (text, record) => (
        <Typography.Text ellipsis={true} style={{ width: 180 }}>
          {record.company}
        </Typography.Text>
      ),
    },
    {
      title: "名前",
      dataIndex: "name",
      width: 180,
      key: "name",
      // fixed: 'left', IE11非対応の為
      render: (text, record) => (
        <Typography.Text ellipsis={true} style={{ width: 160 }}>
          {record.name}
        </Typography.Text>
      ),
    },
    {
      title: "訪問時間",
      width: 160,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      },
    },
    {
      width: 180,
      title: "作成者",
      dataIndex: "creatorName",
      key: "creatorName",
      render: (text, record) => (
        <Typography.Text ellipsis={true} style={{ width: 160 }}>
          {record.creatorName}
        </Typography.Text>
      ),
    },
    {
      width: 150,
      title: "デバイス名",
      dataIndex: "deviceName",
      key: "deviceName",
      render: (text, record) => (
        <Typography.Text ellipsis={true} style={{ width: 130 }}>
          {record.deviceName}
        </Typography.Text>
      ),
    },
    {
      width: 150,
      title: "対応者",
      dataIndex: "handlerName",
      key: "handlerName",
    },
    {
      width: 100,
      title: "受付番号",
      dataIndex: "number",
      key: "number",
    },
    {
      width: 170,
      title: "メモ",
      dataIndex: "memo",
      key: "memo",
      render: (text, record) => (
        <Typography.Text ellipsis={true} style={{ width: 160 }}>
          {record.memo}
        </Typography.Text>
      ),
    },
    {
      title: "アクション",
      dataIndex: "detail",
      width: 120,
      fixed: "right",
      key: "detail",
      render: (text, record) => (
        <Button
          onClick={() => {
            setVisitorModalVisible(true);
            setRecordData(record);
          }}
        >
          詳細
        </Button>
      ),
    },
  ];

  const handleVisitorModalVisible = useCallback(() => {
    setVisitorModalVisible(false);
  }, [setVisitorModalVisible]);

  const handleExportModalVisible = useCallback(() => {
    setExportModalVisible(false);
  }, [setExportModalVisible]);

  useEffect(() => {
    (async () => {
      await dispatch(fetchSettingAction());
      await dispatch(fetchHistoryList());
      setLoading(false);
    })();
  }, [dispatch]);

  return (
    <ConfigProvider locale={jaJP}>
      <Card
        style={{ margin: "16px" }}
        extra={
          <div>
            <Button loading={false} icon={<UploadOutlined />} type="primary" onClick={() => setExportModalVisible(true)}>
              {localize.ExportButtonImport}
            </Button>
          </div>
        }
      >
        <ExportModal visible={exportModalVisible} handleExportModalVisible={handleExportModalVisible} />
        <Spin size="large" spinning={loadingState.state || loading}>
          <Table columns={columns} scroll={{ x: 1300 }} style={{ minHeight: "0%" }} dataSource={historyList} />
          <VisitorModal
            editMode={true}
            mailPostButtonVisible={false}
            visible={visitorModalVisible}
            record={recordData}
            handleModalVisible={handleVisitorModalVisible}
          />
        </Spin>
      </Card>
    </ConfigProvider>
  );
};

HistoryList.whyDidYouRender = {
  // logOnDifferentValues: true,
  customName: "HistoryList",
};
export default HistoryList;
