import { createSelector } from "reselect";

const commonSelector = (state) => (state.settings.common)
const corporationIdSelector = (state) => state.settings.corporationId;
const advanceSelector = (state) => state.settings.advance;

export const getCommonSelector = createSelector([commonSelector], (common) => common)
  // console.log(`common`, common)
  // switch (visibilityFilter) {
  //   case 'SHOW_ALL':
  //     return common;
  //   case 'SHOW_COMPLETED':
  //     return common.filter(t => t.completed);
  //   case 'SHOW_ACTIVE':
  //     return common.filter(t => !t.completed);
  // }

export const getCorporationIdSelector = createSelector([corporationIdSelector], (corporationId) => corporationId);

export const getAdvanceSelector = createSelector([advanceSelector], (advance) => advance);
