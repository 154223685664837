import React from "react";
import { withRouter } from "react-router-dom";
import Global from "../models/Global";
import { UserList, Import, Export, SettingCommon, SettingDevices, Departments, HistoryList } from "./index";
import "../assets/mainlayout.css";
import { IndexedDBHelpers } from "helpers";
import * as settingOperations from "reducks/settings/operations";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCorporationIdSelector, getCommonSelector } from "reducks/settings/selectors";

// import antd
import {
  DesktopOutlined,
  DownloadOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  SettingOutlined,
  TabletOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Modal, Image } from "antd";

const { Header, Content, Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

var MenuKey = {
  User: "User",
  UserList: "UserList",
  Setting: "Setting",
  SettingCommon: "SettingCommon",
  SettingDevices: "SettingDevices",
  Import: "Import",
  Export: "Export",
  Deparments: "Departments",
  Visitor: "Visitor",
  VisitorHistoryList: "VisitorHistoryList",
};

const localize = Global.localize;

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.onMenuSelected = this.onMenuSelected.bind(this);
    this.onLogout = this.onLogout.bind(this);

    this.state = {
      collapsed: false,
      currentMenuKey: MenuKey.UserList,
      // currentMenuKey: MenuKey.Import,
      isInitializing: false,
      collaborationService: "",
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onMenuSelected = ({ item, key, selectedKeys }) => {
    this.setState({ currentMenuKey: key });
  };

  componentDidMount() {
    const auth = Global.auth;
    const db = Global.firestore;
    this._isMounted = true;
    var self = this;

    auth.onAuthStateChanged(async (user) => {
      const db = Global.firestore;
      if (user) {
        const result = await this.props.actions.fetchSettings(this.props.location.state);
        const uid = String(user.uid);

        let corporationId = null;

        // ReduxのDispatchが非同期のため、storeにデータがなければindexedDBから取得する
        // ルーティング
        if (this.props.location.state) {
          corporationId = this.props.location.state.corporationId
        } 
        else {
          const indexedDBTable = await IndexedDBHelpers.getTable({ dbName: "wowdeskLocalStorageDb", tableName: "user", key: "login" });
          corporationId = String(indexedDBTable.corporationId);
        }

        const collaborationService = this.props.common.collaborationService;
        // self.props.history.push("/main");
        var userData = await db.collection(corporationId).doc("db").collection("users").doc(uid).get();

        self.setState({ isInitializing: false, displayName: userData.data().display_name, collaborationService: collaborationService });
      } else {
        // サインアウト(Authなし)
        await auth.signOut();
        process.nextTick(() => {
          self.props.history.push("/login");
        });
      }
    });
  }

  // ログアウト
  onLogout() {
    const modal = Modal.confirm();

    modal.update({
      title: localize.Logout,
      content: localize.LogoutConfirm,
      transitionName: "am-slide-down",
      okText: localize.Yes,
      cancelText: localize.Cancel,
      onOk: async (e) => {
        await this.props.actions.resetSetting();
        var firebase = Global.firebase;
        await firebase.auth().signOut();
        this.props.history.push("/login");
        await IndexedDBHelpers.deleteDB({ dbName: "wowdeskLocalStorageDb", tableName: "user" });
        modal.destroy();
      },
      onCancel: (e) => {
        modal.destroy();
      },
    });
  }

  renderMainForm() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div
            className="logo"
            style={{
              background: "rgb(233, 236, 239)",
              paddingTop: "15px",
              paddingBottom: "15px",
              paddingLeft: "15px",
              paddingRight: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Image src={`${process.env.PUBLIC_URL}/WowDesk_logo_big1@3x.png`} preview={false} />
              {!this.state.collapsed && <span style={{ color: "#7B7C7D" }}>{localize.ManagementScreen}</span>}
            </div>
          </div>
          <Menu
            theme="dark"
            className="ant-menu"
            defaultSelectedKeys={[this.state.currentMenuKey]}
            defaultOpenKeys={[MenuKey.User, MenuKey.Setting, MenuKey.Visitor]}
            mode="inline"
            onSelect={this.onMenuSelected}
          >
            {/* メンバー */}
            <SubMenu
              key={MenuKey.User}
              title={
                <span>
                  <TeamOutlined />
                  <span>{localize.MenuKeyMember}</span>
                </span>
              }
            >
              <Menu.Item key={MenuKey.UserList}>
                <OrderedListOutlined />
                <span>{localize.MenuKeyUserList}</span>
              </Menu.Item>
              <Menu.Item key={MenuKey.Import}>
                <DownloadOutlined />
                <span>{localize.MenuKeyImport}</span>
              </Menu.Item>
              <Menu.Item key={MenuKey.Export}>
                <UploadOutlined />
                <span>{localize.MenuKeyExport}</span>
              </Menu.Item>
              <Menu.Item key={MenuKey.Deparments}>
                <TeamOutlined />
                <span>{localize.MenuKeyDepartments}</span>
              </Menu.Item>
            </SubMenu>

            {/* 訪問者 */}
            <SubMenu
              key={MenuKey.Visitor}
              title={
                <span>
                  <TeamOutlined />
                  <span>{localize.MenuKeyVisitors}</span>
                </span>
              }
            >
              <Menu.Item key={MenuKey.VisitorHistoryList}>
                <DatabaseOutlined />
                <span>{localize.MenuKeyVisitorsHistoryList}</span>
              </Menu.Item>
            </SubMenu>

            {/* 設定 */}
            <SubMenu
              key={MenuKey.Setting}
              title={
                <span>
                  <SettingOutlined />
                  <span>{localize.MenuKeySetting}</span>
                </span>
              }
            >
              <Menu.Item key={MenuKey.SettingCommon}>
                <DesktopOutlined />
                <span>{localize.MenuKeySettingCommon}</span>
              </Menu.Item>
              <Menu.Item key={MenuKey.SettingDevices}>
                <TabletOutlined />
                <span>{localize.MenuKeySettingDevices}</span>
              </Menu.Item>
            </SubMenu>
            {/* WTAdmin banner */}
            {!this.state.collapsed && this.state.collaborationService === "wowtalk" && (
              <a href="https://biz.wowtalk.org/admin/" target="_blank" rel="noreferrer noopener">
                <div style={{ textAlign: "center", background: "#287acd", paddingTop: 5, paddingBottom: 5 }}>
                  <Image src={`${process.env.PUBLIC_URL}/logo_wtadmin_login.png`} preview={false} />
                </div>
              </a>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              background: "rgb(233,236,239)",
              padding: 0,
              borderColor: "rgb(220,222,226)",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Menu theme="light" mode="horizontal" style={{ lineHeight: "63px", background: "rgb(233,236,239)" }}>
              <SubMenu
                title={
                  <span>
                    <UserOutlined />
                    {this.state.displayName ? this.state.displayName : localize.NoName}
                  </span>
                }
              >
                <Menu.Item onClick={this.onLogout}>
                  <LogoutOutlined />
                  {localize.Logout}
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Header>
        

          <Content>
            {this.state.currentMenuKey === MenuKey.UserList ? <UserList location={this.props.location} /> : null}
            {this.state.currentMenuKey === MenuKey.SettingCommon ? <SettingCommon /> : null}
            {this.state.currentMenuKey === MenuKey.SettingDevices ? <SettingDevices /> : null}
            {this.state.currentMenuKey === MenuKey.Import ? <Import /> : null}
            {this.state.currentMenuKey === MenuKey.Export ? <Export /> : null}
            {this.state.currentMenuKey === MenuKey.Deparments ? <Departments loading={this.state.syncing} /> : null}
            {this.state.currentMenuKey === MenuKey.VisitorHistoryList ? <HistoryList /> : null}
          </Content>

          <Footer style={{ textAlign: "center" }}>{localize.Copyright}</Footer>
        </Layout>
      </Layout>
    );
  }

  componentWillUnmount() {
    // Unmount時の処理を記述
    this._isMounted = false;
  }

  render() {
    // console.log(`this.state`, this.state);
    // console.log(`MainLayout Rendering`)
    // console.log(`this.props.location.state`, this.props.location.state)
    return this.renderMainForm();
  }
}

// export default withRouter(MainLayout);

// container component
const mapStateToProps = (state) => {
  return {
    corporationId: getCorporationIdSelector(state),
    common: getCommonSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...settingOperations }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withRouter(MainLayout));
