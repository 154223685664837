import * as Actions from "./actions";
import initialState from "../../store/initialState";

export const DevicesReducer = (state = initialState.devices, action) => {
  switch (action.type) {
    case Actions.FETCH_DEVICES_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
