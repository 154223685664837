import Global from "../models/Global";
import EmailValidator from "email-validator";
import moment from "moment";

moment.locale("ja");

const UserHelpers = {
  createRegexFromDomainOfEmail: (domains) => {
    if (domains.length === 1) {
      return new RegExp(`^.*@${domains.join("")}$`);
    } else {
      return new RegExp(`^.*@(${domains.join("|")})$`);
    }
  },

  checkValidEmail: function (email) {
    return EmailValidator.validate(email);
  },

  loadUserList: async (corporationId) => {
    const userList = await UserHelpers.getUserList(corporationId);

    return userList;
  },

  getUserList: (corporationId) => {
    const db = Global.firestore;

    let promise = new Promise((resolve, reject) => {
      db.collection(corporationId)
        .doc("db")
        .collection("users")
        .orderBy("display_name")
        .get()
        .then((userDocs) => {
          let dataSource = [];
          userDocs.forEach((doc) => {
            const user = doc.data();
            let item = {
              uid: doc.id,
              displayName: user.display_name,
              email: user.email,
              isAdmin: user.is_admin,
              pronounce: user.pronounce,
              disabled: user.disabled,
              teamsGroup: user.type_information.microsoft.group,
              microsoftObjectId: user.type_information.microsoft.object_id,
              wowTalkGroup: user.type_information.wowtalk.group,
              wowTalkAccount: user.type_information.wowtalk.account,
            };

            dataSource.push(item);
          });

          resolve(dataSource);
        })
        .catch((err) => {
          reject(err);
        });
    });

    return promise;
  },

  callCreateUser: async (data) => {
    var user = await UserHelpers.createUser(data);

    // console.log('createUser', JSON.stringify(data));
    return user;
  },

  callUpdateUser: async (data) => {
    var user = await UserHelpers.updateUser(data);

    // console.log('updateUser', JSON.stringify(data));
    return user;
  },

  callDeleteUser: async (data) => {
    var user = await UserHelpers.deleteUser(data);

    // console.log('deleteUser', JSON.stringify(user));
    return user;
  },

  createUser: function (data) {
    const func = Global.func;
    const createUser = func.httpsCallable("user-create");

    return createUser({
      user: {
        displayName: data.displayName,
        email: data.email,
        isAdmin: data.isAdmin,
        pronounce: data.pronounce,
        password: data.password,
        wowTalkAccount: data.wowTalkAccount,
        wowTalkGroup: data.wowTalkGroup,
        teamsGroup: data.teamsGroup,
        tenantId: data.tenantId,
        corporationId: data.corporationId,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });

    // let promise = new Promise(function (resolve, reject) {
    //   createUser({
    //     user: {
    //       displayName: data.displayName,
    //       email: data.email,
    //       isAdmin: data.isAdmin,
    //       pronounce: data.pronounce,
    //       password: data.password,
    //       wowTalkAccount: data.wowTalkAccount,
    //       wowTalkGroup: data.wowTalkGroup,
    //       teamsGroup: data.teamsGroup,
    //       tenantId: data.tenantId,
    //       corporationId: data.corporationId,
    //     },
    //   })
    //     .then((res) => {
    //       resolve(res);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });

    // return promise
  },

  updateUser: function (data) {
    // const func = Global.func;
    const db = Global.firestore;
    const { uid, corporationId, collaborationService } = data;

    const userDoc = db.doc(`${corporationId}/db/users/${uid}`);

    // if (data.pronounce) user.pronounce = data.pronounce;

    // console.log(`data`, data)
    let promise = new Promise(async (resolve, reject) => {
      // WowTalk連携の時のみ、emailが編集できるので重複チェックを行う
      if (data.email && collaborationService === "wowtalk") {
        const userDocs = await db.collection(corporationId).doc("db").collection("users").where("email", "==", data.email).get();

        console.log(`userDocs.docs.length`, userDocs.docs.length);
        if (1 < userDocs.docs.length) {
          return reject({ data: { code: { code: 400, message: "Multiple users" } } });
        }
      }

      userDoc
        .set(
          {
            disabled: data.disabled,
            display_name: data.displayName,
            email: data.email ? data.email : null,
            is_admin: data.isAdmin,
            pronounce: data.pronounce,
            type_information: {
              microsoft: {
                group: data.teamsGroup,
              },
              wowtalk: {
                group: data.wowTalkGroup,
                account: data.wowTalkAccount,
              },
            },
            updated_at: moment().toDate(),
          },
          { merge: true }
        )
        .then(() => resolve({ data: { code: 200 } }))
        .catch((e) => {
          console.error(e);
          reject({ data: { code: 400 } });
        });
    });

    // let promise = new Promise((resolve, reject) => {
    //   updateUser({ user })
    //     .then((res) => {
    //       resolve(res);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //     });
    // });

    return promise;
  },

  deleteUser: (data) => {
    const { uid, corporationId } = data;
    const db = Global.firestore;

    let promise = new Promise(async (resolve, reject) => {
      try {
        await db.collection(corporationId).doc("db").collection("users").doc(uid).delete();
        return resolve();
      } catch (e) {
        return reject(e);
      }
    });

    return promise;
  },

  duplicateCheck: (dataArray, targetKey) => {
    let targetArray = [];

    for (let item of dataArray) {
      if (item[targetKey]) targetArray = [...targetArray, item[targetKey]];
    }

    let duplicateItemArray = targetArray.filter((value, index, array) => array.indexOf(value) === index && index !== array.lastIndexOf(value));

    return {
      result: duplicateItemArray.length === 0,
      duplicateCount: duplicateItemArray.length,
      duplicateItem: duplicateItemArray,
    };
  },
};

export default UserHelpers;
